import { TaskFormError } from '../types/enums';
/**
 * 编辑空投任务相关需求
 */
class EditTask {
    /**
     * 获取语言表单是否全空
     */
    static getIsLangFormEmpty({ banner, title, incAvatar, incName, detail, requirements, }) {
        return !banner && !title && !incAvatar && !incName && !detail && requirements.length === 0;
    }
    /**
     * 获取语言表单必填项目已经填写
     */
    static getIsLangFormFilled({ title, incName, detail, requirements, }) {
        return title && incName && detail && requirements.length > 0;
    }
    /**
     * 获取是否每个语言表单填写的条件匹配(条件数量一致+每个条件表单一致)
     * @param infos 已经填写完全的表单
     */
    static getIsLangFormsMatched(infos) {
        if (infos.length === 1) {
            return true;
        }
        const existNotMatched = infos.find((item, index) => {
            const prev = infos[index - 1];
            if (!index) {
                return false;
            }
            if (item.requirements.length !== prev.requirements.length) {
                return true;
            }
            return item.requirements.find((req, rid) => {
                const prevReq = prev.requirements[rid];
                if (req.fields.length !== prevReq.fields.length) {
                    return true;
                }
                return req.fields.find((field, fid) => field.type !== prevReq.fields[fid].type);
            });
        });
        return Boolean(!existNotMatched);
    }
    /**
     * 获取表单必填项已填写
     * @desc 多语言至少填写一种;
     * @desc 多语言表单必须对应;
     * @desc 不校验开始<结束时间;
     */
    static getFormInvalid(form) {
        if (!form.startTime || !form.endTime) {
            return TaskFormError.NoTime;
        }
        if (form.startTime > form.endTime) {
            return TaskFormError.InvalidTime;
        }
        if (form.startTime < new Date()) {
            return TaskFormError.StartBeforeNow;
        }
        // 编辑了的语言表单（非全空）
        const editedLangForm = form.infos.filter((item) => !this.getIsLangFormEmpty(item));
        if (editedLangForm.length === 0) {
            return TaskFormError.NoLangForm;
        }
        const notFilledLangForm = editedLangForm.find((item) => !this.getIsLangFormFilled(item));
        if (notFilledLangForm) {
            return TaskFormError.NotFilledLangForm;
        }
        const isMatched = this.getIsLangFormsMatched(editedLangForm);
        if (!isMatched) {
            return TaskFormError.NotMatchedRequirement;
        }
        return TaskFormError.NoError;
    }
}
export default EditTask;
