import { defineComponent } from 'vue';
export default defineComponent({
    name: 'AvatarBox',
    props: {
        src: {
            type: String,
            required: true,
        },
    },
});
