import Language from '@/core/Interactors/Language';
import { Lang } from '@/core/types/enums';
import { ref } from 'vue';
const languages = Language.getLanguages();
const useLangSelect = (defaultLang = Lang.en) => {
    const selectedLang = ref(defaultLang);
    return {
        languages,
        selectedLang,
    };
};
export default useLangSelect;
